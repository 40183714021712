.u-header {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}
.u-header__section {
  position: relative;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 1px 10px rgb(151 164 175 / 10%);
}

.logo-title{
  color: #000;
  font-weight: bold;
}
#cfts-table .thead-light th {
    color: #ffffff;
    background-color: #191e3a;
    border-color: #fff;
}
#cfts-table tbody tr:hover {
    color: #000;
    background-color: rgba(255,255,255,1);
}

#cfts-table tbody a{
    color: #fff; 
}

#cfts-table tbody tr:hover a {
    color: #3084BF; 
}